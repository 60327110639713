import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";
import ElementPlus from "element-plus";
import * as ElementPlusIcons from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import ru from "element-plus/dist/locale/ru.mjs";

import "./assets/base.less";

import setupInterceptors from "./services/axios/setupInterceptors";
import { createVuesticEssential, VaButton } from "vuestic-ui";
import "vuestic-ui/styles/essential.css";
import "vuestic-ui/styles/grid.css";
import "vuestic-ui/styles/reset.css";
import "vuestic-ui/styles/typography.css";
import { createVuestic } from "vuestic-ui";
import vuesticGlobalConfig from "./services/vuestic-ui/global-config";
import "vuestic-ui/css";
import { useAuthStore } from "./store";

const pinia = createPinia();

//onst app = createApp(App).use(createPinia()).use(router);
//  .use(store)
const app = createApp(App);
app.use(pinia);
app.use(router);
for (const [key, component] of Object.entries(ElementPlusIcons)) {
  app.component(key, component);
}

app.use(ElementPlus, {
  locate: ru,
});
app.use(createVuestic({ config: vuesticGlobalConfig }));

//app.use(createVuesticEssential({ components: { VaButton } }));
app.mount("#app");
setupInterceptors(useAuthStore());
