<script setup>
import { reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import useAuthStore from "@/store/authStore";
const authStore = useAuthStore();
import { useForm, useToast } from "vuestic-ui";
const router = useRouter();
const route = useRoute();
const formElement = ref();
const { isValid } = useForm("formRef");
const { init } = useToast();
const validateLogin = (rule, value, callback) => {
  if (value === "") callback(new Error("Please input the login"));
  callback();
};

const validatePass = (rule, value, callback) => {
  if (value === "") callback(new Error("Please input the password"));
  callback();
};

const formData = reactive({
  email: "",
  password: "",
});
const submitForm = async () => {
  try {
    await authStore.login(formData);
    router.push(authStore.returnUrl || { name: "FirstPage" });
  } catch (e) {
    if (e.response.status === 401){
      init({ message: "Неверный логин или пароль", color: "danger" });
    }
  }
};
</script>

<template>
  <div class="home-page">
    <div class="title">
      <h1>Вход</h1>
    </div>
    <div class="d-flex justify-center mt-3 room-item center">
      <va-form ref="formElement" :model="formData">
        <va-input
          v-model="formData.email"
          class="mb-3"
          label="Email"
          :rules="[(v) => Boolean(v) || 'Email обязательно']"
        />
        <va-input
          v-model="formData.password"
          class="mb-3"
          type="password"
          label="Пароль"
          color="#000"
          :rules="[
            (v) => Boolean(v) || 'Введите пароль',
            (v) => v.length >= 1 || 'Пароль должен быть более 1 символа',
          ]"
        />

        <div class="d-flex justify-center mt-3">
          <va-button
            type="input"
            class="mr-6 mb-2 mrg"
            @click="submitForm(formElement)"
            >Вход</va-button
          >

          <va-button
            type="input"
            class="mr-6 mb-2"
            @click="router.push({ name: 'Register' })"
            >Регистрация</va-button
          >
        </div>
      </va-form>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url("@/assets/styles.css");
@import url("@/assets/base.less");
.center {
  margin: 0 auto;
}
.title {
  padding-top: 5%;
  text-align: center;
}
button {
  margin-right: 4%;
}
</style>
