import { defineStore } from 'pinia';
import File from '@/models/File';


var base =   `${ import.meta.env.VITE_BACKEND_URL}/`; 


export default defineStore({
	id: 'imageLightbox',
	state: () => ({
		show: false,
		images: null as string | string[] | null,
		index: 0,
	}),
	actions: {
		showImage(image: File) {
			this.images = base + image.path;
			this.show = true;
		},
		showImages(images: File[], index?: number) {
			this.images = images.map((x) => base + x.path);
			this.index = index ?? 0;
			this.show = true;
		},
	},
});
