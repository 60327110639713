<template>
	<div
		class="va-page-not-found justify-center pb-5"
		:style="pageNotFoundStyle"
		v-bind="$attrs">
		<div class="va-page-not-found__inner align-center">
			<slot name="image" />
			<div class="va-page-not-found__title text-center mb-4">Такой страницы не существует</div>
			<slot />

       <va-button
       @click="() =>router.push({ name: 'FirstPage'})">
         <va-icon name="home" />
       </va-button>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useColors } from 'vuestic-ui';

	import { computed } from 'vue';
    import router from "@/router";

	const { colors } = useColors();


	defineProps<{
		withoutButton?: boolean;
	}>();

	const pageNotFoundStyle = computed(() => {
		return {
			color: colors.secondary,
			backgroundColor: colors.backgroundPrimary,
		};
	});
</script>

<style lang="scss">
	.va-page-not-found {
		min-height: 100vh;
		display: flex;
		padding-top: 10%;
		position: relative;

		&__inner {
			display: flex;
			flex-direction: column;
			max-width: 100%;

			.va-icon-vuestic {
				width: 19rem;
				height: 2rem;
				margin-bottom: 13%;
			}
		}

		&__title {
			font-size: 3rem;
			line-height: 1.25em;
			font-weight: 500;
		}
	}
    @media (max-width: 480px) {
        .va-page-not-found{
          float: none;
          width: auto;
          text-align: center;
        }
    }
</style>
