<script setup>
import { onMounted, ref, watchEffect, computed } from "vue";
import roomService from "@/services/roomService";
import { getRoomConnection } from "@/signalr/connections";
import Chat from "@/components/Chat.vue";
import moment from "moment";
import AvatarNameCityListItem from "@/components/user/AvatarNameCityListItem.vue";
const genderCount = ref([]);
const roomConnection = ref();
const waitingUsers = ref([]);

const props = defineProps({
  value: Object,
});
const chatId = ref(0);
//onMounted(async () => {});
watchEffect(async () => {
  roomConnection.value = await getRoomConnection();
  genderCount.value = await roomConnection.value.getGenderCountInWeitingRoom(
    props.value.id
  );
  waitingUsers.value = await roomConnection.value.getUsersInWeitingRoom(
    props.value.id
  );
  if (!!props.value.chat) chatId.value = props.value.chat.id;
  //  console.log("count1 " + genderCount[0]);
});
const creationDate = ref(new Date());
creationDate.value = moment(props.value.creationDate).format(
  "YYYY-MM-DD HH:m:s"
);
</script>
<template>
  <div class="room-item">
    <div class="text-room">
      <h1 class="name changeContent">
        Название Комнаты:
        {{ props.value.name }}
      </h1>
      <span v-if="props.value.cityRequired">
        <i> Только для тех кто - </i>
      </span>
      <span v-else>
        <i> Желательно вы из - </i>
      </span>
      <span> из города:</span>
      <span class="changeContent"> {{ props.value.city?.name }}</span>

      <div>
        Комната создана
        <span class="changeContent">
          {{ creationDate }}
        </span>
      </div>
      <br />
      Вместимость:
      {{ props.value.capacity }} чел.
      <br />
      <span v-if="props.value.ageRequired">
        <i> Необходимо чтобы ваш возраст был: </i>
      </span>
      <span v-else> Желательно ваш возраст: от </span>
      <span class="changeContent"
        >{{ props.value.minAge }} до {{ props.value.maxAge }}</span
      >
      <br />
      Описание Комнаты:
      <span class="changeContent"> {{ props.value.description }}</span>
      <br />
      <span>
        Сейчас мы ждем
        <span v-if="genderCount[0] > 0">
          <span class="changeContent"> {{ genderCount[0] }}-х</span>
          <span class="men-class"> Мужчин </span>
        </span>
        <span v-if="genderCount[1] > 0">
          <span class="changeContent"> {{ genderCount[1] }}-х</span> Женщин
        </span>
      </span>
    </div>
    <div class="avatars" v-if="waitingUsers.length > 0">
      <span> В комнате вас ждут: <br /> </span>
      <!-- <div class="avatar-box">
        <div v-for="user in waitingUsers" :key="user.id" class="avatar-label">
          <div class="user-name">{{ user.userName }}</div>
          <Avatar class="avatar" :avatar-path="user.avatarPath" />
        </div> -->
        <va-list>
          <va-list-label>Мы друг другу понравились </va-list-label>
          <va-list-item v-for="user in waitingUsers"
          :key = "user.id" class="list__item">
          <AvatarNameCityListItem
          :user = "user"
          :needGender=true
          />
          </va-list-item>
        </va-list>
      </div>
    </div>

</template>

<style scoped lang="less">
@import url("@/assets/styles.css");
@import url("@/assets/base.less");

@item-height: 3rem;

.text-room {
  float: left;
  width: 60%;
  color: black;
}
.name {
  padding-left: 10px;
}
.men-class {
  color: blue;
}
.avatars {
  width: 40%;
  float: right;
}
.avatar-box {
  overflow: auto;
}
.avatar-label {
  float: left;
  padding: 2em;
}
.avatar {
  transition: all 0.5s;
}
.avatar:hover {
  transform: scale(2);
}
.user-name {
  padding-bottom: 5px;
}
</style>
