import { createRouter, createWebHistory } from 'vue-router'
import { redirectIfNotAuthorized } from './redirectIfNotAuthorized'
import { routes } from './routes'

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})


router.beforeEach(redirectIfNotAuthorized)

export default router