<script setup>
import moment from "moment";
import { ref, onUnmounted, watch, onMounted } from "vue";
import UserVideo from "./UserVideo.vue";
import Timer from "@/components/Timer.vue";
import openViduClient from "@/openvidu/openvidu";
import { showError } from "@/utils/notify";
import useAuthStore from "@/store/authStore";

const store = useAuthStore();

const props = defineProps({
  sessionToken: String,
  secondsForSession: Number,
});

const publisher = ref();
const partner = ref();
const session = ref();
const sessionStartTime = ref();

const connect = async () => {
  try {
    session.value = await openViduClient.initSession();

    session.value.onStreamCreated((event) => {
      partner.value = session.value.subscribe(event.stream);
    });
    session.value.onStreamDestroyed((event) => {
      if (
        partner.value.stream.connection.connectionId ===
        event.stream.connection.connectionId
      )
        partner.value = null;
    });

    await session.value.connect(props.sessionToken, {
      userName: store.user.userName,
    });

    publisher.value = await openViduClient.initPublisher(undefined, {
      audioSource: undefined, // The source of audio. If undefined default microphone
      videoSource: undefined, // The source of video. If undefined default webcam
      publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
      publishVideo: true, // Whether you want to start publishing with your video enabled or not
      resolution: "640x480", // The resolution of your video
      frameRate: 30, // The frame rate of your video
      insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
      mirror: false, // Whether to mirror your local video or not
    });
    await session.value.publish(publisher.value);
  } catch (e) {
    if (e.name === "BROWSER_NOT_SUPPORTED") {
      showError(e.message);
    } else {
      showError("Unknown error");
      console.log(e);
    }
    throw e;
  }
};

onMounted(async () => {
  await connect();
});

onUnmounted(() => {
  session.value.disconnect();
});

watch(
  () => props.sessionToken,
  async () => {
    if (props.sessionToken) {
      await connect();
      sessionStartTime.value = moment.utc();
    }
  }
);
</script>

<template>
  <div class="wrapper">
    <el-row :gutter="10">
      <el-col :sm="12">
        <UserVideo v-if="publisher" :stream-manager="publisher" />
      </el-col>
      <el-col :sm="12">
        <UserVideo v-if="partner" :stream-manager="partner" />
      </el-col>
    </el-row>
    <Timer
      :countUntil="
        moment.utc(sessionStartTime).add(props.secondsForSession, 'seconds')
      "
    />
  </div>
</template>

<style scoped>
.wrapper {
  padding: 10px;
}
</style>
