//import store from "@/store";
import { useAuthStore } from "@/store/authStore";


export const redirectIfNotAuthorized = (to, from) => {
  const authstore = useAuthStore();
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !authstore.isAuthenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: "/FirstPage",
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  }
};
