import { defineStore } from 'pinia';
import File from '@/models/File';
import fileService from '@/services/fileService';
import userService from '@/services/userService';


export const useFileStore = defineStore({
	id: 'file',
	state: () => ({
		items: [] as File[],

	}),
	actions:{
		async uploadPhotos(files:any[],  userId: number, fileId?: number) {
			this.loading = true;
			try{
				const filesUpload: File[] = await fileService.uploadPhotos(files,  userId, fileId);
				this.items.push(...filesUpload);
			}
			finally{
				this.loading = false;
			}
		},
		async getUserPhotos(userId: number) {
			this.loading = true;
			try{
				const filesLoad: File[]  = await fileService.getUserPhotos(userId);
				this.items =filesLoad;
			}
			finally{
				this.loading = false;
			}
		},
		async deleteFileById( fileId: number) {
			this.loading = true;
			try{
				await fileService.deleteFileById(fileId);
				this.items= this.items.filter(x=>x.id !== fileId);
			}
			finally{
				this.loading = false;
			}
		},
		async deleteFileByName( name: string, userId: number) {
			this.loading = true;
			try{
				await fileService.deleteFile(name, userId);
				this.items=	this.items.filter(x=>x.name !== name);
			}
			finally{
				this.loading = false;
			}
		},
		async assignAvatar (fileId: number, userId:number) {
			this.loading = true;
			try{
			const avatar = await userService.assignAvatar(fileId, userId);
			this.items = this.items.map(item=>  {item.type = 1
				return item;
			})
			this.items.find(x=>x.id === avatar.id).type = 0
			}
			finally{
				this.loading = false;
			}
		},
	},
	getters: {
		itemPaths(state): string[] {
			return state.items.map((x) => x.path);
		},
	},
});

export default useFileStore;
