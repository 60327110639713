<script setup>
import moment from "moment";
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as roomStatus from "@/constants/roomStatus";
import * as gender from "@/constants/gender";
import { getRoomConnection } from "@/signalr/connections";
import roomService from "@/services/roomService";
import OneOnOneCall from "@/components/openVidu/OneOnOneCall.vue";
import Chat from "@/components/Chat.vue";
import Avatar from "@/components/user/Avatar.vue";
import { useToast } from 'vuestic-ui';

const { init } = useToast();
const route = useRoute();
const router = useRouter();
const room = ref();
const sessionToken = ref();
const roomConnection = ref();
const chatId = ref();

onMounted(async () => {
  room.value = await roomService.getRoom(route.params.roomId);
  chatId.value = room.value.chat.id;

  if (room.value) {
    roomConnection.value = await getRoomConnection();

    roomConnection.value.onUserEnteredTheRoom((user, allUsers) => {
      room.value.users = allUsers;
    });
    roomConnection.value.onUserLeftTheRoom((user, allUsers) => {
      room.value.users = allUsers;
    });

    roomConnection.value.onSessionStarted((token, pairChatId) => {
      room.value.status = roomStatus.Running;
      sessionToken.value = token;
      chatId.value = pairChatId;
    });
    roomConnection.value.onRoomClosed(() => {
      router.push({ name: "RoomRating" });
    });

    await roomConnection.value.enterRoom(room.value.id);
  }
});

onUnmounted(async () => {
  if (room.value) {
    await roomConnection.value.leaveRoom(room.value.id);

    roomConnection.value.offUserEnteredTheRoom();
    roomConnection.value.offUserLeftTheRoom();
    roomConnection.value.offSessionStarted();
    roomConnection.value.offRoomClosed();
  }
});

const startSessions = async () => {
  try {
    await roomService.startSessions(room.value.id);
  }
  catch (error) {
    const message =  error.response.data.replace(/[^а-яёА-ЯЁ ]/g,"")
    init({ message: `${message}` });
  }
  }

</script>

<template>
  <div class="home-page">
    <div v-if="!room">
      <p>Комната не найдена</p>
    </div>
    <div v-else-if="room.status === roomStatus.Closed">
      <p>Комната закрыта</p>
    </div>
    <div v-else>
      <VaCard stripe stripe-color="meetprimary">
        <vaCardTitle>
          <h1>Название чат комнаты - {{ room.name }}</h1>
        </vaCardTitle>
        <VaCardContent>
          <div v-if="room.status === roomStatus.Awaiting">
            <va-button
            title="Запускайте если равное количество женщин и мужчин в комнате"
              style="margin-top: 20px"
              color="#E7717D"
              gradient="true"
              round
              @click="startSessions"
              >Запустить общение</va-button >
            <p>Участники в ожидании запуска:</p>

            <div v-for="user in room.users" class="avatar-label">
              <div class="user-name">
                {{ user.userName }} -
                {{ user.gender === gender.Male ? "мужчина" : "женщина" }}
              </div>

              <Avatar class="avatar" :avatar-path="user.avatarPath" />
            </div>
          </div>

          <div v-else-if="sessionToken">
            <OneOnOneCall
              :sessionToken="sessionToken"
              :secondsForSession="room.secondsForSession"
            />
          </div>
          <div class="row">
            <div class="flex xs6 chat">
              <Chat :chatId="chatId" />
            </div>
          </div>
        </VaCardContent>
      </VaCard>
    </div>
  </div>
</template>
<style scoped>
@import url("@/assets/styles.css");
@import url("@/assets/base.less");
.text-room {
  float: left;
  width: 60%;
  color: black;
}
.name {
  padding-left: 10px;
}
.men-class {
  color: blue;
}
.avatars {
  width: 40%;
  float: right;
}
.avatar-box {
  overflow: auto;
}
.avatar-label {
  float: left;
  padding: 2em;
}
.avatar {
  transition: all 0.5s;
}
.avatar:hover {
  transform: scale(2);
}
.user-name {
  padding-bottom: 5px;
}
.chat {
  float: right;
}
</style>
