<script setup>
import { useAuthStore } from "@/store/authStore";
import { computed, reactive, toRefs } from "vue";
import { useForm } from "vuestic-ui/web-components";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const authstore = useAuthStore();
const canCreateRoom =
  authstore.user?.roles.filter((r) => r.name === "moderator").length > 0;
const toRote = () => {
  if (authstore.user) toRooms();
  else toRegisty();
};
const toRegisty = () => {
  router.push({ name: "Register" });
};
const toRooms = () => {
  router.push({ name: "Home" });
};
computed(() => {
  document.querySelectorAll(".ciclegraph").forEach((ciclegraph) => {
    let circles = ciclegraph.querySelectorAll(".circle");
    let angle = 360 - 90,
      dangle = 360 / circles.length;
    for (let i = 0; i < circles.length; ++i) {
      let circle = circles[i];
      angle += dangle;
      circle.style.transform = `rotate(${angle}deg) translate(${
        ciclegraph.clientWidth / 2
      }px) rotate(-${angle}deg)`;
    }
  });
  const state = reactive({
    smile_girl: "/assets/img/smile_girl.jpg",
  });

  const { smile_girl } = toRefs(state);
});
</script>

<template>
  <div>
    <div class="home-page">
      <div class="block1">
        <div class="frame4">
          <va-card>
            <va-card-title>
              <p class="live">Мы за живое общение</p>
            </va-card-title>
            <va-card-content>
              <p class="textlive">
                В DateVideo вы сможете провести блиц-свидания в наших онлайн
                комнатах, где участвуют одинаковое количество мужчин и женщин
                подобранных примерно одного возраста из одного города.
              </p>
              <va-button
                style="margin-top: 20px"
                color="#E7717D"
                gradient="true"
                round
                @click="toRote"
                >{{
                  authstore.user ? "Комнаты общения" : "Регистрация"
                }}</va-button
              >
            </va-card-content>
          </va-card>
        </div>
        <va-card>
          <va-card-content>
            <div class="z-block">
              <!-- блок с картинками    -->
              <img
                class="circle-people"
                id="image2"
                src="@\assets\img\dating1.jpg"
                alt="..."
              />
              <img
                class="circle"
                id="image3"
                src="@\assets\img\arrow-up-l.jpg"
                alt="..."
              />
              <img
                class="circle-people"
                id="image4"
                src="@\assets\img\dating2.jpg"
                alt="..."
              />
              <img
                class="circle"
                id="image5"
                src="@\assets\img\arrow-up-r.jpg"
                alt="..."
              />
              <img
                class="circle-people"
                id="image6"
                src="@\assets\img\dating3.jpg"
                alt="..."
              />
              <img
                class="circle"
                id="image1"
                src="@\assets\img\arrow-down-l.jpg"
                alt="..."
              />
              <img
                class="circle-people"
                id="image8"
                src="@\assets\img\dating4.jpg"
                alt="..."
              />
              <img
                class="circle"
                id="image7"
                src="@\assets\img\arrow-down.jpg"
                alt="..."
              />
              <img
                class="circle"
                id="center-image"
                src="@\assets\img\arrow-two-dir.jpg"
                alt="..."
              />

              <img src="@\assets\img\Z-backgr.jpg" class="background" />
            </div>
          </va-card-content>
        </va-card>
      </div>
      <div class="block2">
        <h4 class="live">Присоединяйся к нам</h4>
        <el-row :gutter="20" justify="center">
          <el-col :span="8">
            <va-card :body-style="{ padding: '0px' }">
              <img src="@\assets\img\smile_girl.jpg" class="image" />
              <div style="padding: 10px">
                <div class="bottom">
  
                  <el-button text class="button">
                    <h5 class="live">Ищите общие интересы</h5>
                  </el-button>

                  <label class="block2-label">
                    В комнате тебя ждут от 3 кандидатов, с каждым из которых вы
                    сможете пообщаться в течении 5-10 минут, в конце сессии ты
                    сможешь проголосовать за того, кто на тебя произвел
                    наибольшее впечатление
                  </label>
                  <va-button
                    style="margin-top: 20px"
                    color="#E7717D"
                    gradient="true"
                    round
                    @click="toRote"
                  >
                    {{ authstore.user ? "Комнаты общения" : "Регистрация" }}
                  </va-button>
                </div>
              </div>
            </va-card>
          </el-col>
          <el-col :span="8">
            <el-card :body-style="{ padding: '0px' }">
              <img src="@\assets\img\dating3.jpg" class="image" />
              <div style="padding: 10px">
                <div class="bottom">

                  <el-button text class="button">
                    <h5 class="live">Пригласите на свидание</h5>
                  </el-button>
                  <label class="block2-label">
                    Вы сможете узнать скольким людям вы понравились, при
                    взаимной совпадении семпатий вы сможете получить контакты
                    кандидата и пригласить его на свидание!
                  </label>
                  <va-button
                    style="margin-top: 20px"
                    color="#E7717D"
                    gradient="true"
                    round
                    @click="toRote"
                  >
                    {{ authstore.user ? "Комнаты общения" : "Регистрация" }}
                  </va-button>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card :body-style="{ padding: '0px' }">
              <img src="@\assets\img\dating4.jpg" class="image" />
              <div style="padding: 10px">
                <div class="bottom">
                  <el-button text class="button">
                    <h5 class="live">Найдите свою судьбу</h5>
                  </el-button>
                  <label class="block2-label">
                    Наш алгоритм подбирает кандидатов по общей теме, возрасту и
                    городу, чтобы вы смогли максимально быстро найти того
                    партнера, с кем вам будет интересно провести досуг.
                  </label>
                  <va-button
                    style="margin-top: 20px"
                    color="#E7717D"
                    gradient="true"
                    round
                    @click="toRote"
                  >
                    {{ authstore.user ? "Комнаты общения" : "Регистрация" }}
                  </va-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<style>
/* .va-button--normal {
  --va-0-background-color: linear-gradient(
    268.07deg,
    #e7717d 11.25%,
    #e7cd719e 135.73%
  );
  background: linear-gradient(268.07deg, #e7717d 11.25%, #e7cd719e 135.73%);
} */

.el-row {
  margin-bottom: 20px;
  height: 732px;
}
.block2-label {
  width: 264px;
  height: 216px;

  /* Text 1 */

  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
}
.outer-avatar {
  width: 70px; /* задайте желаемую ширину внешнего изображения */
  height: 70px; /* задайте желаемую высоту внешнего изображения */
  border: 10px solid #ccc; /* задайте нужную ширину и цвет рамки */
}

.inner-avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-clip: padding-box; /* обрезаем фон до границ padding контейнера */
  background-image: url("/assets/img/ellipse4.jpg");
}

.block2 {
  margin-top: 100px;
  background: #fff3d2;
  height: 980px;
  width: 1196px;
  left: 122px;
  top: 738px;
  border-radius: 12px;
}
#center-image {
  width: 100px;
  height: 100px;
}
.circle-people {
  width: 130px;
  height: 130px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.circle {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100px;
  height: 100px;
}
.circle:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
.image {
  float: left;
  position: relative;
  left: 20%;
  width: 60%;
  height: 50%;
}
/* .image::before {
  content: "";
  background: linear-gradient(#0070d8, #2cdbf1, #83eb8a);
  position: absolute;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
  border-radius: 50%;
  z-index: 1;
} */
#image1 {
  transform: rotate(45deg) translate(200px) rotate(-45deg);
}
#image2 {
  transform: rotate(90deg) translate(200px) rotate(-90deg);
}
#image3 {
  transform: rotate(135deg) translate(200px) rotate(-150deg);
}
#image4 {
  transform: rotate(180deg) translate(200px) rotate(-185deg);
}
#image5 {
  transform: rotate(-135deg) translate(200px) rotate(120deg);
}
#image6 {
  transform: rotate(-90deg) translate(200px) rotate(90deg);
}
#image7 {
  transform: rotate(-38deg) translate(200px) rotate(25deg);
}
#image8 {
  transform: translate(200px);
}

.block1 {
  justify-content: center;
  display: flex;
  align-items: normal;
}

.z-block {
  position: relative;
  margin: 0px 0px 0px 0px;
}
.z-block background {
  min-height: 100%;
  min-width: 640px;
  width: 100%;
  height: auto;

  top: 0;
  left: 0;
  float: left; /*Задаем обтекание*/
}
.textlive {
  /*styleName: Text 1;*/
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
}
.live {
  /* Мы за живое общение */
  width: 362px;
  height: 45px;
  /* T1 */
  font-family: "Nunito";
  font-style: normal;
  /* font-weight: 800; */
  font-size: 32px;
  /* line-height: 95%; */
  /* or 30px */
  display: flex;
  align-items: center;
  text-align: center;

  /* Pink */
  color: #e7717d;

  /* Inside auto layout */
  /* flex: none;
order: 0;
flex-grow: 0; */
}
.frame4 {
  height: 315px;
  width: 588px;
  left: 122px;
  top: 234px;
  border-radius: 12px;
  padding: 0px 28px 0px 28px;
  /* Frame 4 */
  float: left; /*Задаем обтекание*/
  /* Auto layout */

  /*  display: flex; */
  /* flex-direction: column;
justify-content: center;
align-items: flex-start;
padding: 0px 28px;
gap: 22px; */

  /* position: absolute; */
  width: 588px;
  height: 315px;
  left: 122px;
  top: 234px;

  /* Orange */

  background: #fff3d2;
  border-radius: 12px;
}
.home-page {
  height: 4622px;
  width: 1440px;
  left: 4176px;
  top: 590px;
  border-radius: 0px;
}
</style>
