import { OpenVidu } from "openvidu-browser"

const openViduInstance = new OpenVidu()
let session = null

export default {
    initPublisher: async (targetEl, properties) => await openViduInstance.initPublisherAsync(targetEl, properties),
    initSession: async () => {
        session = openViduInstance.initSession()

        const result = {
            connect: async (token, metadata) => { 
                await session.connect(token, metadata)
                window.addEventListener('beforeunload', session.disconnect)
            },
            subscribe: (stream, targetEl) => session.subscribe(stream, targetEl),
            publish: async (publisher) => await session.publish(publisher),
            disconnect: () => {
                session.disconnect();
	            window.removeEventListener('beforeunload', session.disconnect);
                session = null;
            },

            onStreamCreated: (cb) => session.on("streamCreated", cb),
            onStreamDestroyed: (cb) => session.on("streamDestroyed", cb),
            onException: (cb) => session.on("exception", cb),
        }

        return result
    }
}