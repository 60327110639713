<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useAuthStore } from "@/store/authStore";
import { useRoute, useRouter } from "vue-router";
import * as roomStatus from "@/constants/roomStatus";
import * as gender from "@/constants/gender";
import roomService from "@/services/roomService";
import OneOnOneCall from "@/components/openVidu/OneOnOneCall.vue";
import Avatar from "@/components/user/Avatar.vue";
import { getRoomConnection } from "@/signalr/connections";
import moment from "moment";

const route = useRoute();
const room = ref();
const store = useAuthStore();
const pairUsers = ref([]);

onMounted(async () => {
  room.value = await roomService.getRoom(route.params.roomId);

  pairUsers.value.push(
    ...room.value.pairs
      .filter((x) => x.userLeftId == store.user.id)
      .map((x) => ({ ...x.userRight, liked: x.userLeftLiked }))
  );
  pairUsers.value.push(
    ...room.value.pairs
      .filter((x) => x.userRightId == store.user.id)
      .map((x) => ({ ...x.userLeft, liked: x.userRightLiked }))
  );
});

const like = async (user) => {
  await roomService.likeUser(room.value.id, user.id);
  user.liked = true;
};

const unlike = async (user) => {
  await roomService.unlikeUser(room.value.id, user.id);
  user.liked = false;
};
</script>

<template>
  <div class="home-page">
    <VaCard>
      <VaCartContent>
    <div class="room-item">
      <VaList fit class="list mg">
        <VaListLabel>
          <div>
            <h1>Результаты комнаты #{{ route.params.roomId }}</h1>
            <span>Проголосуйте за того кто вам понравился.</span>
            <span> Если будет взаимность вы сможете продолжить общение</span>
          </div>
        </VaListLabel>
        <div v-if="!room">
          <p>Комната не найдена</p>
        </div>
        <div v-else-if="room.status !== roomStatus.Closed">
          <p>Комната пока не закрыта</p>
        </div>
        <div v-else>
          <VaListItem class="list__item" v-for="user in pairUsers">
            <VaListItemSection avatar>
              <Avatar :avatar-path="user.avatarPath" />
            </VaListItemSection>
            <VaListItemSection>
              <VaListItemLabel>{{ user.userName }}</VaListItemLabel>
            </VaListItemSection>
            <VaListItemSection>
              <va-button
                style="margin-top: 20px"
                color="#E7717D"
                gradient="true"
                round
                v-if="!user.liked"
                @click="() => like(user)"
              >
                Нравится
              </va-button>
              <va-button
                v-else
                style="margin-top: 20px"
                color="#CACACA"
                round
                @click="() => unlike(user)"
                >Отмена</va-button
              >
            </VaListItemSection>
          </VaListItem>
        </div>
      </VaList>
    </div>
    </VaCartContent>
    </VaCard>
  </div>
</template>
<style scoped>
.list {
  margin: 0 auto;
}
.mg {
  margin: 10%;
}
</style>
