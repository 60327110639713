<script setup>
import { ref, onBeforeMount, computed } from "vue";
import moment from "moment";

const props = defineProps({
    countUntil: moment
})
const emit = defineEmits(['completed'])

const timer = ref(moment.duration())

const prettyTimer = computed(() => {
    let minutes = timer.value.minutes()
    let seconds = timer.value.seconds()

    if (minutes < 10)
        minutes = "0" + minutes

    if (seconds < 10)
        seconds = "0" + seconds
    
    return minutes + ":" + seconds
})

const interval = setInterval(() => {
    timer.value = moment.duration(props.countUntil.diff(moment.utc()))
    if (timer.value.seconds() < 0){
        clearInterval(interval)
        timer.value = moment.duration()
        emit("completed")
    }
}, 1000)

</script>

<template>
    <span v-if="timer">{{ prettyTimer }}</span>
</template>