<script setup>
import useAuthStore from "@/store/authStore";
import router from "@/router";
const authStore = useAuthStore();

const loginLogout = () => {
  if (!authStore.user) {
    router.push({ name: "Login" });
  } else authStore.logout();
};

const gender = (g) => {
  if (g === 1) return "Мужчина";
  else if (g === 0) return "Женщина";
  else return "";
};
const toRooms = () => {
  if (authStore.user) {
    router.push({ name: "Home" });
  } else router.push({ name: "Login" });
};
const toPersonalAccount = () => {
  if (authStore.user) {
    router.push({ name: "Profile" });
  } else router.push({ name: "Login" });
};
</script>

<template>
  <div class="flex flex-wrap md12 above-menu">
    <div class="mr-2 va-text-no-wrap">
      {{ authStore.user?.city?.name }}
    </div>
    <div class="mr-2 va-text-no-wrap">{{ authStore.user?.userName }}</div>
    <div class="mr-2 va-text-no-wrap">
      {{ gender(authStore.user?.gender) }}
    </div>
    <div class="mr-2 va-text-no-wrap" v-if="authStore.user">
      Возраст: {{ authStore.user?.age }} лет
    </div>
  </div>
  <div class="menu">
    <VaNavbar color="#FFFFFF" textColor="#E7717D">
      <template #left>
        <VaNavbarItem class="logo">
          <router-link to="/" class="logo">
            <div class="logo">
              <img src="@/assets/img/Logo.jpg" alt="LOGO" />
            </div>
          </router-link>
        </VaNavbarItem>
      </template>

      <template #center>
        <VaNavbarItem class="menu-items">
          <router-link to="/"> Главная </router-link>
        </VaNavbarItem>

        <VaNavbarItem class="menu-items" @click="toRooms" >
          <a> Список комнат видео-карусели </a>
        </VaNavbarItem>

        <VaNavbarItem class="menu-items" @click="toPersonalAccount">
          <a> Личный кабинет </a>
        </VaNavbarItem>

        <VaNavbarItem class="menu-items" @click="loginLogout">
          <a> {{ authStore.user ? "Выйти" : "Войти" }}</a>
        </VaNavbarItem>
      </template>
    </VaNavbar>
  </div>
</template>

<style scoped lang="less">
@import url("@/assets/base.less");

.above-menu {
  display: flex;
  margin: 0 auto;
  width: 1440px;
  height: 24px;
  left: 0;
  top: 0;
  color: #ffffff;
  background: linear-gradient(
    268.07deg,
    #e7717d 11.25%,
    rgba(231, 205, 113, 0.62) 135.73%
  );
  padding: 0 60px 0 50px;
}
@media only screen and (max-width: 480px){
  .above-menu {
    display: none;
  }
  .menu {

    width: auto;
    font-family: 1em;
    line-height: normal;
    height: 10%;
    flex-direction: column;
    text-align: center;
  }
}
.menu-items {
  //styleName: T4;
  font-family: 8px;
  font-weight: 700;
  line-height: 22px;
  padding: 1.5rem 3rem 1.5rem 3rem;
  transition: transform 1s;
  a {
    color: #e7717d;
  }
}
.menu-items:hover {
  transform: scale(1.2);
  background-color: #e7717d;
  opacity: 50%;
  a {
    color: #e7cd719e;
  }
}
.menu {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1px 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);

}
.logo {
  display: block;
  &__text {
    color: rgb(255, 208, 208);
    font-weight: bold;
  }
}
.mobile__label{
  border:solid 2px white;
  display:inline-block;
  overflow:hidden;
  padding:12px 9px;
  width:25px;
  white-space: nowrap;
  &:hover{
    cursor:pointer;
    /*ANIMAION POSITION ON HOVER*/
    .label__line{
      margin-left:0px;
    }
    /*ANIMATION DELAY*/
    :nth-child(2){
      .label__line{
        transition-delay:.1s;
      }
    }
    :nth-child(3){
      .label__line{
        transition-delay:.2s;
      }
    }
  }
}
</style>
