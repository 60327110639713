<template>
  <Menu />
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import { getRoomConnection } from "@/signalr/connections";
import Menu from "@/components/Menu.vue";


const router = useRouter();

const roomConnection = ref();

onMounted(async () => {
  roomConnection.value = await getRoomConnection();
  roomConnection.value.onRoomCreated((roomId) => {
    router.push({ name: "Room", params: { roomId: roomId } });
  });
  roomConnection.value.onRoomClosed(() => {
    router.push({ name: "Home" });
  });
});

onUnmounted(() => {
  roomConnection.value?.offRoomCreated();
  roomConnection.value.offRoomClosed();
});
</script>
