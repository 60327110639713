<script setup>
import { computed } from 'vue';
import OpenViduVideo from './OpenViduVideo.vue';

const props = defineProps({
	streamManager: Object
})

const userName = computed(() => {
	const connectionData = JSON.parse(props.streamManager.stream.connection?.data);
	return connectionData.userName;
})
</script>

<template>
	<div v-if="streamManager">
		<OpenViduVideo :stream-manager="streamManager"/>
		<div><p>{{ userName }}</p></div>
	</div>
</template>
