export enum Role {
	Admin = 1,
	Moderator = 2,
	User = 3,
}

export const roles = [

	{
		id: Role.Admin,
		text: 'Администратор',
	},
	{
		id: Role.Moderator,
		text: 'Модератор',
	},
	{
		id: Role.User,
		text: 'Пользователь',
	},

];
export const role = (roleId: Role) => {
	return roles.find((x) => x.id == roleId)?.text;
};

export default role;
