<script setup lang="ts">
import Account from "@/models/Account";
const props = defineProps<{
  user: Account;
  needGender?: Boolean
  needAge?: Boolean
  needCity?: Boolean
}>();
const fileBaseURL =  `${ import.meta.env.VITE_BACKEND_URL}/`;
</script>

<template>
  <VaListItemSection avatar>
    <VaAvatar>
      <img :src="fileBaseURL + props.user.avatarPath" >
    </VaAvatar>
  </VaListItemSection>
    <VaListItemSection>
      <VaListItemLabel>
        {{ props.user.userName }}
      </VaListItemLabel>
      <VaListItemLabel v-if="needGender">
        {{ props.user.gender === 0 ? "Женщина" : "Мужчина" }}
      </VaListItemLabel>
      <VaListItemLabel  v-if="needCity" caption>
        {{ props.user.city.name }}
      </VaListItemLabel>
    </VaListItemSection>
    <VaListItemSection icon>
      <VaIcon
        name="remove_red_eye"
        color="background-element"
      />
    </VaListItemSection>
</template>
