import { defineStore } from "pinia";
//import authService from '@/services/authService';
const localStorageUser = localStorage.getItem("user");
import authService from "@/services/authService";
import { closeConnection } from "@/signalr/connections";
import router from "@/router";
interface State {
  user: any | null;
  status: any;
  tokens: any;
}
export const useAuthStore = defineStore({
  id: "auth",
  state: (): State => ({
    user: localStorageUser ? JSON.parse(localStorageUser) : null,
    //returnUrl: undefined as string | undefined,
    status: {},
    tokens: JSON.parse(localStorage.getItem("tokens")),
  }),

  actions: {
    async login(user: any) {
      const authResponse = await authService.login(user);
      this.user = authResponse.user;
      this.tokens = authResponse.tokens;
      if (this.user) localStorage.setItem("user", JSON.stringify(this.user));
      if (this.tokens)
        localStorage.setItem("tokens", JSON.stringify(this.tokens));
    },
    logout() {
      this.user = null;
      localStorage.removeItem("user");
      this.tokens = null;
      localStorage.removeItem("tokens");
      router.push({ name: "Login" });
      closeConnection();
    },
    setUser(user: any) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    refreshToken(tokens: any) {
      this.tokens = tokens;
      if (tokens) localStorage.setItem("tokens", JSON.stringify(tokens));
      else localStorage.removeItem("tokens");
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return !!state.tokens?.accessToken;
    },
    // // user: (state) => {
    //   return state.user;
    // },
    // tokens: (state) => {
    //   return state.tokens;
    // },
  },
});
export default useAuthStore;
