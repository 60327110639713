<script setup lang="ts">
import roomService from "@/services/roomService";
import { onMounted, onUnmounted, reactive, ref } from "vue";
import { useAuthStore } from "@/store/authStore";
import { getRoomConnection } from "@/signalr/connections";
import { showError } from "@/utils/notify";
import moment from "moment";
import chatService from "@/services/chatService";
import getContentHeight from "@/helpers/getContentHeight";
import MessageItem from "./MessageItem.vue";
import Message from "@/models/Message";

const props = defineProps({
  chatId: Number,
});
const authstore = useAuthStore();
const roomConnection = ref();
const inputMessageEl = ref();
const messages = ref([] as Message[]);
const formData = reactive({
  message: "",
});

const submitForm = (e) => {
  e.preventDefault();
  inputMessageEl.value.focus();
  if (!formData.message) return;
  roomConnection.value.sendRoomMessage(props.chatId, formData.message);
  formData.message = "";
};

const addMessage = (message: Message) => {
  try {
    let newMesage: Message = {
      text: message.text,
      date: message.date,
      user: message.user,
      userId: message.userId,
    };
    messages.value.push(newMesage);
  } catch {
    showError("Сообщение не будет отображено", "Ошибка отображения сообщения");
  }
};

onMounted(async () => {
  messages.value = await chatService.getMessages(props.chatId);
  messages.value.forEach(addMessage);

  roomConnection.value = await getRoomConnection();
  roomConnection.value.onSendMessage(addMessage);
});

onUnmounted(() => {
  roomConnection.value.offSendRoomMessage();
});
</script>

<template>
  <div>
    <va-form ref="form" :model="formData" @submit="submitForm">
      <va-input
        class="inputs__item"
        v-model="formData.message"
        placeholder="Ваше сообщение"
        @keypress.esc="cleaner"
        ref="inputMessageEl"
        autosize
      />
      <div>
        <div class="d-flex">
          <va-button
            class="inputs__item"
            @click="submitForm"
            :disabled="isSending"
          >
            Отправить сообщение
          </va-button>
        </div>
      </div>
    </va-form>
    <va-list-label color="meetprimary">чат</va-list-label>
    <va-scroll-container vertical :style="{ 'max-height': getContentHeight() }">
      <div v-for="message in messages.slice().reverse()">
        <MessageItem :message="message" />
      </div>
      <div ref="bottomOfComments"></div>
    </va-scroll-container>
  </div>
</template>

<style lang="scss" scoped>
.current-reply {
  position: relative;
  display: flex;
  align-items: left;
  justify-items: center;
  &__card {
    width: 95%;
    justify-content: left;
    margin: 5px;
  }
  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
}

.inputs {
  display: flex;

  &__item {
    margin: 0.4rem;
  }
}
</style>
