import { createIconsConfig } from "vuestic-ui/web-components";

export default {
  colors: {
    variables: {
      meetprimary: "#E7717D",
      primaryhover: "#775c9b33",
      meetbackround: "#fff3d2",
      primaryalt: "#00AC86",
      blue: "#4075ff",
      grey: "#64748b",
      dark: "#1e293b",
      darkalt: "#334155",
      light: "#f1f5f9",
      danger: "#ff5858",

      meetsuccess: "#00AC86",
      meetmeetdanger: "#f26f75",

      safetycrossok: "#00AC86",
      safetycrossfirstaid: "#00b2d2",
      safetycrossmed: "#f26f75",
    },
  },
  icons: createIconsConfig({
    fonts: [
      {
        name: /(brandico|entypo|fa|fontelico|glyphicon|iconicstroke|maki|openwebicons)-(.*)/,
        resolveFromRegex: (font, code) => ({
          class: `${font} ${font}-${code}`,
        }),
      },
    ],
  }),
  breakpoint: {
    enabled: true,
    bodyClass: true,
    thresholds: {
      xs: 0,
      sm: 320,
      md: 640,
      lg: 1024,
      xl: 1440,
    },
  },
  components: {
    VaIcon: {
      sizesConfig: {
        defaultSize: 19,
        sizes: {
          small: 14,
          medium: 19,
          large: 26,
        },
      },
    },
    VaButton: {
      round: false,
      textColor: "#FFF",
      color: "meetprimary",
    },
    VaButtonToggle: {
      textColor: "#FFF",
      color: "meetprimary",
    },
    VaInput: {
      color: "meetprimary",
    },
    VaSelect: {
      color: "meetprimary",
    },
    VaDateInput: {
      color: "meetprimary",
      firstWeekday: "Monday",
      weekdayNames: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      monthNames: [
        "Янв",
        "Фев",
        "Март",
        "Апр",
        "Май",
        "Июнь",
        "Июль",
        "Авг",
        "Сен",
        "Окт",
        "Нояб",
        "Дек",
      ],
    },
    VaDatePicker: {
      color: "meetprimary",
      firstWeekday: "Monday",
      weekdayNames: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      monthNames: [
        "Янв",
        "Фев",
        "Март",
        "Апр",
        "Май",
        "Июнь",
        "Июль",
        "Авг",
        "Сен",
        "Окт",
        "Нояб",
        "Дек",
      ],
    },
    VaTimeInput: {
      color: "meetprimary",
    },
    VaSwitch: {
      color: "meetprimary",
    },
    VaFileUpload: {
      color: "meetprimary",
    },
    VaCounter: {
      color: "meetprimary",
      textColor: "meetprimary",
    },
    VaTreeView: {
      color: "meetprimary",
    },
    VaCheckbox: {
      color: "meetprimary",
    },
    VaChip: {
      color: "meetprimary",
      textColor: "#FFF",
    },
    VaPopover: {
      color: "meetprimary",
      textColor: "#FFF",
    },
    VaModal: {
      noOutsideDismiss: true,
      maxWidth: "90vw",
    },
    presets: {
      VaButton: {
        outline: {
          backgroundOpacity: 0,
          hoverBehaviour: "opacity",
          hoverOpacity: 0.07,
          pressedBehaviour: "opacity",
          pressedOpacity: 0.13,
          borderColor: "meetprimary",
          textColor: "meetprimary",
        },
        plain: {
          round: false,
          plain: true,
          hoverBehaviour: "mask",
          hoverOpacity: 0.15,
          pressedBehaviour: "mask",
          pressedOpacity: 0.13,
        },
        secondary: {
          borderColor: "meetprimary",
          textColor: "meetprimary",
        },
        primary: {
          round: false,
          textColor: "meetlight",
          color: "meetprimary",
        },
        plaincard: {
          round: false,
          plain: true,
          hoverBehaviour: "mask",
          hoverOpacity: 0.15,
          pressedBehaviour: "mask",
          pressedOpacity: 0.13,
        },
      },
    },
  },
};
