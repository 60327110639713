<script setup>
const props = defineProps({
  avatarPath: String,
});

let avatarSrc;
if (props.avatarPath) {
  avatarSrc = import.meta.env.VITE_BACKEND_URL + "/" + props.avatarPath;
}

</script>

<template>
  <va-avatar :src="avatarSrc" />
</template>
