import apiAxios from "@/services/axios/apiAxios";

const chatService = {
  getMessages: async (chatId) => {
    const response = await apiAxios.get(`/api/chat/${chatId}/messages`);
    const messages = response.data;
    return messages;
  },
};

export default chatService;
