import { HubConnectionBuilder } from "@microsoft/signalr";
import store from "@/store/authStore";
import authService from "@/services/authService";
const client = new HubConnectionBuilder()
  .withUrl(import.meta.env.VITE_BACKEND_URL + "/hub", {
    accessTokenFactory: () =>
      JSON.parse(localStorage.getItem("tokens")).accessToken,
  })
  .withAutomaticReconnect()
  .build();

const startConnection = async () => {
  const clientStarting = client.start();
  clientsStarting.push(clientStarting);
  await clientStarting;
};

const clientsStarting = [];

export const getRoomConnection = async () => {
  const roomConnection = {
    enterRoom: async (roomId) => await client.invoke("EnterRoomAsync", roomId),
    leaveRoom: async (roomId) => await client.invoke("LeaveRoomAsync", roomId),
    sendRoomMessage: async (chatId, message) => {
      await client.invoke("SendMessageAsync", chatId, message);
    },
    getGenderCountInWeitingRoom: async (roomId) =>
      await client.invoke("GetGenderCountInWeitingRoom", roomId),

    getUsersInWeitingRoom: async (roomId) =>
      await client.invoke("GetUsresInWitingRoom", roomId),

    onRoomCreated: (func) => client.on("RoomCreated", func),

    offRoomCreated: () => client.off("RoomCreated"),

    onSendMessage: (func) => client.on("SendMessage", func),

    offSendRoomMessage: () => client.off("SendMessage"),

    onSessionStarted: (func) => client.on("SessionStarted", func),
    offSessionStarted: () => client.off("SessionStarted"),

    onUserEnteredTheRoom: (func) => client.on("UserEnteredTheRoom", func),
    offUserEnteredTheRoom: () => client.off("UserEnteredTheRoom"),

    onUserLeftTheRoom: (func) => client.on("UserLeftTheRoom", func),
    offUserLeftTheRoom: () => client.off("UserLeftTheRoom"),

    onRoomClosed: (func) => client.on("RoomClosed", func),
    offRoomClosed: () => client.off("RoomClosed"),
  };

  const roomConnectionWithLogging = {
    ...roomConnection,
    enterRoom: async (roomId) => {
      console.log("enterRoom invoked", roomId);
      await roomConnection.enterRoom(roomId);
    },
    leaveRoom: async (roomId) => {
      console.log("leaveRoom invoked", roomId);
      await roomConnection.leaveRoom(roomId);
    },
    sendMessage: async (chatId, message) => {
      console.log("sendMessage invoked", chatId, message);
      await roomConnection.sendRoomMessage(chatId, message);
    },

    onRoomCreated: (func) =>
      client.on("RoomCreated", (...args) => {
        console.log("onRoomCreated event", args);
        func(...args);
      }),
    onSendMessage: (func) =>
      client.on("SendMessage", (...args) => {
        console.log("onSendMessage event", args);
        func(...args);
      }),
    onSessionStarted: (func) =>
      client.on("SessionStarted", (...args) => {
        console.log("onSessionStarted event", args);
        func(...args);
      }),
    onUserEnteredTheRoom: (func) =>
      client.on("UserEnteredTheRoom", (...args) => {
        console.log("onUserEnteredTheRoom event", args);
        func(...args);
      }),
    onUserLeftTheRoom: (func) =>
      client.on("UserLeftTheRoom", (...args) => {
        console.log("onUserLeftTheRoom event", args);
        func(...args);
      }),
    onRoomClosed: (func) =>
      client.on("RoomClosed", (...args) => {
        console.log("onRoomClosed event", args);
        func(...args);
      }),
  };

  const result = !import.meta.env.DEV
    ? roomConnection
    : roomConnectionWithLogging;
  if (client.state === "Connected") {
    return result;
  } else if (client.state === "Disconnected") {
    try {
      await startConnection();
    } catch (e) {
      if (
        e.message ===
        "Failed to complete negotiation with the server: Error: Unauthorized: Status code '401'"
      ) {
        try {
          const tokens = JSON.parse(localStorage.getItem("tokens"));
          await authService.refreshToken(tokens);
          clientsStarting.pop();
          await startConnection();
        } catch (e) {
          clientsStarting.pop();
          // store.logout();
        }
      }
    }
  } else {
    await clientsStarting[0];
  }

  if (clientsStarting.length > 0) clientsStarting.pop();

  return result;
};

export const closeConnection = async () => {
  await client.stop();
};
