<script setup>
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
	streamManager: Object
})

const videoElement = ref()

const mountVideo = () => props.streamManager.addVideoElement(videoElement.value)

onMounted(() => {
	mountVideo()
})

watch(() => props.streamManager,
	() => mountVideo()
)

</script>

<template>
	<video ref="videoElement" autoplay/>
</template>

<style>
	video {
		width: 100%
	}
</style>