<template>
	<main>
		<component :is="layout">
			<router-view></router-view>
		</component>
	</main>
</template>
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Nunito:wght@500;600;700;800&display=swap" rel="stylesheet">
<script setup>
	import { computed } from "@vue/reactivity"
	import { useRoute } from "vue-router"
	import EmptyLayout from '@/layouts/EmptyLayout.vue'
	import MainLayout from '@/layouts/MainLayout.vue'

	const route = useRoute()

	const layout = computed(() => {
		const layout = route.meta.layout || "empty"

		if (layout === "empty")
			return EmptyLayout
		else if (layout === "main")
			return MainLayout
	})

</script>
