<script setup>
import RoomList from "@/components/room/RoomList.vue";
import { computed, reactive, toRefs } from "vue";
import { useAuthStore } from "@/store/authStore";
const authstore = useAuthStore();

const canCreateRoom =
  authstore.user?.roles.filter(
    (r) => r.name === "moderator" || r.name === "admin"
  ).length > 0;
computed(() => {
  document.querySelectorAll(".ciclegraph").forEach((ciclegraph) => {
    let circles = ciclegraph.querySelectorAll(".circle");
    let angle = 360 - 90,
      dangle = 360 / circles.length;
    for (let i = 0; i < circles.length; ++i) {
      let circle = circles[i];
      angle += dangle;
      circle.style.transform = `rotate(${angle}deg) translate(${
        ciclegraph.clientWidth / 2
      }px) rotate(-${angle}deg)`;
    }
  });
});
</script>

<template>
  <div class="home-page">
    <div>
      <h1 class="textlive">Комнаты к которым можно присоединиться</h1>
    </div>
    <div>
      <!-- пока всем можно создавать Комнаты -->
      <router-link to="/room/new" v-if="true">
        <va-button
          style="margin-top: 0.5em"
          color="#E7717D"
          gradient="true"
          round
          >Создать комнату</va-button
        >
      </router-link>
    </div>
    <div>
      <RoomList />
    </div>
  </div>
</template>
<style>
@import url("@/assets/styles.css");
@import url("@/assets/base.less");
.textlive {
  /*styleName: Text 1;*/
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
}
</style>
