<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { showError } from "@/utils/notify";
import roomService from "@/services/roomService";
import cityService from "@/services/cityService";
import { useForm } from "vuestic-ui";
//import { useToast } from "vuestic-ui";

//const { init } = useToast();

const router = useRouter();
const formElement = ref();

const formData = reactive({
  name: "",
  capacity: 4,
  secondsForSession: 60,
  minAge: 18,
  maxAge: 65,
  ageRequired: false,
  cityId: "",
  cityRequired: false,
  description: "",
});
const loading = ref(true);
const cityes = ref();
//const { isValid, validate } = useForm("formRef");
onMounted(async () => {
  loading.value = true;
  cityes.value = await cityService.getCities();
  // cityes.value = list.map((item) => ({
  //   value: item.id,
  //   label: item.name,
  // }));
  loading.value = false;
});

const submitForm = async () => {
  if (formData.minAge > formData.maxAge) {
    showError("Минимальный возраст должен быть меньше максимального");
    return false;
  }
  if (formData.cityId === "") formData.cityId = 0;
  try {
    const roomId = await roomService.createRoom(formData);
    router.push({ name: "Room", params: { roomId: roomId } });
  } catch (error) {
    showError(error);
    console.log(error);
  }
};
</script>

<template>
  <VaCard stripe stripe-color="meetprimary">
    <va-inner-loading :loading="loading">
      <VaCardTitle>
        <span class="va-h4"> Создать видео-чат комнату </span>
      </VaCardTitle>

      <VaCardContent class="flex xs12 lg12 align-content">
        <div>
          <va-form ref="formElement">
            <div class="flex xs12 lg12 md12 align-content-center">
              <va-input
                v-model="formData.name"
                type="text"
                label="Название комнаты"
                :rules="[(value: string) => (value && value.length > 0) || 'Укажите название']"
              />
            </div>
            <div class="flex-col xs3 md4 offset-md2 offset-lg2 label1">
              <VaCounter
                v-model="formData.capacity"
                label="Вместимость комнаты"
                manual-input
                :min="2"
                :max="16"
                :step="2"
                :rounded="true"
                :rules="[(value:number)=>(value=>2 && value<16)|| 'Укажите вместимость от 2 до 16 чел.']"
              />
            </div>
            <div class="flex xs12 md4 lg3">
              <VaCounter
                v-model="formData.secondsForSession"
                label="Время общения одной пары(в сек.)"
                :min="20"
                :max="600"
                manual-input
                :step="10"
                :rules="[(value:number)=>(value>2 && value<16)|| 'Укажите время общения одной пары от 20 до 600 сек.']"
              />
            </div>
            <div class="flex xs12 lg3 va-spacing-x-4">
              <VaCounter
                v-model="formData.minAge"
                label="Минимальный возраст"
                :min="18"
                :max="99"
                manual-input
                :rules="[(value:number)=>(value>18 && value<99 && value < formData.maxAge)|| 'Укажите минимальный возраст']"
              />
              <VaCounter
                v-model="formData.maxAge"
                label="Максимальный возраст"
                :min="19"
                :max="99"
                manual-input
                :rules="[(value:number)=>(value>18 && value<99 && value > formData.minAge)|| 'Укажите минимальный возраст']"
              />
              <VaSwitch
                v-model="formData.ageRequired"
                true-label="Только для тех, кто подходит по возрасту"
                false-label="Можно и другим по возрасту"
              />
            </div>
            <div class="flex xs12 lg3">
              <VaSelect
                v-model="formData.cityId"
                label="Выберите город"
                :options="cityes"
                value-by="id"
                text-by="name"
                :rules="[(value: any) => value || 'Укажите город' ]"
              />
              <VaSwitch
                v-model="formData.cityRequired"
                true-label="Только для тех, кто живет в городе"
                false-label="Можно из других городов"
                :disabled="!formData.cityId"
              />
            </div>
            <div class="flex xs12">
              <va-textarea
                class="plus-contrast"
                v-model="formData.description"
                label="Описание комнаты"
              />
            </div>
            <va-button type="primary" @click="submitForm(formElement)"
              >Создать комнату</va-button
            >
          </va-form>
        </div>
      </VaCardContent>
    </va-inner-loading>
  </VaCard>
</template>
<style scoped>
.label1 {
  overflow: visible;
}
</style>
