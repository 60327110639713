import meetApi from '@/services/axios/apiAxios';

const fileUrl = '/api/File';

const fileService = {
	async uploadFile(content:any, typeFile: number, fileId?: number) {
		let path= ""
		const formData = new FormData();
		formData.append('file', content);
		let response = await meetApi.post(`${fileUrl}/upload-photo/${typeFile}?id=${fileId}`, formData,{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		if (response.status === 200){
			path= response.data;
				return path;
		}
		return  response.data;
	},
	async uploadPhotos(files:any[],  userId: number, fileId?: number) {

		let paths= [];
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append("files", files[i]);
		}
	
		let response = await meetApi.post(`${fileUrl}/upload-photos/${userId}`, formData,{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		if (response.status === 200){
			paths= response.data;
				return paths;
		}
		return  response.data;
	},
	async getUserPhotos(userId: number) {
		let response = await meetApi.get(`${fileUrl}/user-photos/${userId}`);
		if (response.status === 200){
			return response.data;
		}
		return  response.data;
	},
	async deleteFile(name: string, userId: number) {

		return meetApi.delete(`${fileUrl}`, {
			params: {
				name,
				userId,
			},
		});
	},
	async deleteFileById( fileId: number) {
		return meetApi.delete(`${fileUrl}/id/${fileId}`);
	},
}

export default fileService;
