<script setup lang="ts">
  import { ref } from 'vue'
  import fileService from '@/services/fileService';
  import { useToast } from 'vuestic-ui';
  import useFileStore from '@/store/fileStore';
  import { useAuthStore } from '@/store/authStore';
  const userStore = useAuthStore();
  const userId =ref()
 
  const emit = defineEmits(['uploaded'])
	const { init } = useToast();
  const fileStore = useFileStore();
	const gallery = ref();
  const imageUrl = ref('')
  let path = ref();
  if(userStore.user) {
    userId.value = userStore.user.id
  }
	const props = defineProps<{
		typeFile: 'avatar' | 'galleryPhoto' | 'message';
    fileId?: 0;

	}>();
  const galleryPhoto = ref([]) as any;
  function cleaner() {
		path.value = [];
	}
	async function loadFiles(event: Iterable<unknown> | ArrayLike<unknown>) {
    gallery.value = await fileService.uploadPhotos( galleryPhoto.value, userId.value);
		let typeFile = 0;
		switch (props.typeFile) {
			case 'avatar':
				typeFile = 0;
        fileStore.items.push(gallery.value);
				break;
			case 'galleryPhoto':
				typeFile = 1;
							fileStore.items.push(gallery.value);
							break;
				break;
			case 'message':
				typeFile = 3;
				break;
		}
    let fileId = 0
		try {
			Array.from(event).forEach(async (file) => {
		//		gallery.value = await fileService.uploadFile(file, typeFile, fileId);
				switch (props.typeFile) {
					case 'avatar':
							fileStore.items.push(gallery.value);
						break;
					case 'galleryPhoto':
          fileStore.items.push(gallery.value);
						break;
					case 'message':
						break;
				}
			});
		init({ message: `Файлы успешно загружены` });
		} catch (er) {
			init({ message: `Произошла ошибка при загрузке файлов ${er.message}` });
		}
		cleaner();
	}
  const deleteFile = async (file: any ) => {
    try {
      await fileService.deleteFile(file.name, userStore.user.id);
      fileStore.items = fileStore.items.filter((x) => x.name !== name);
      init({ message: `Файл успешно удален` });
    } catch {
      init({ message: `Произошла ошибка при удалении файлов` });
    }
  }
  </script>

<template>
    <VaFileUpload
    v-model="galleryPhoto"
    type="gallery"
    uploadButtonText="Загрузить фото"
    undoButtonText="Удалить"
    file-types="image/gif,image/png,image/jpeg,image/jpg,image/heic"
    @fileAdded="loadFiles($event)"
    @file-removed="(file) =>  deleteFile(file)"
    />
     

</template>
  
<style scoped>
  .avatar-uploader .avatar {
    width: 100%;
  }
</style>

<style>
  .avatar-uploader .el-upload {
    width: 178px;
    height: 178px;
    display: flex;
    position: relative;
    align-items: center;
    border: 1px dashed #727d8d;
    border-radius: 6px;
    overflow: hidden;
    transition: 500ms;
  }
  
  .avatar-uploader .el-upload:hover {
    border-color: #0066ff;
  }

  .el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
  }
</style>