<script setup lang="ts">
import File from "@/models/File";
import useImageLightboxStore from "@/store/imageLightboxStore";
import userService from "@/services/userService";
import fileService from "@/services/fileService";
import { useAuthStore } from "@/store";
import { onMounted, watchEffect } from "vue";
import useFileStore from '@/store/fileStore';
import { useToast } from 'vuestic-ui';


const authStore = useAuthStore();
const fileStore = useFileStore();
const imgExtensions = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
const fileBaseURL =  `${ import.meta.env.VITE_BACKEND_URL}/`; 
const { init } = useToast();


const imageLightboxStore = useImageLightboxStore();


const props = defineProps<{
  userId?: number|undefined;
}>();
// const showImage = (index: number) => {
//   const images = props.files.filter((x) => x.path.match(imgExtensions));
//   imageLightboxStore.showImages(images, index);
// };

const assignAvatar = async(fileId)=>{
 await fileStore.assignAvatar(fileId, authStore.user.id)
  // props.files.find(x=>x.id===fileId).type = isAvatar.type;
  // props.files.find(x=>x.id !== fileId && x.type === 0).type = 1
}

const deleteFile = async (file: any ) => {
    try {
      await fileStore.deleteFileByName(file.name, authStore.user.id);
      init({ message: `Файл успешно удален` });
    } catch {
      init({ message: `Произошла ошибка при удалении файлов` });
    }
  }
const deleteFileById = async (id: number ) => {
  try {
  await fileStore.deleteFileById(id);
//props.files = props.files.filter((x) => x.id !== id); 
  init({ message: `Файл успешно удален` });
  } catch { 
  init({ message: `Произошла ошибка при удалении файлов` });
  }
}
onMounted(async()=>{
  if(props.userId){
    fileStore.getUserPhotos(props.userId);
  }
})

</script>

<template>
  <div class="row gallery">
    <div class="card" v-for="(file, i) in fileStore.items">
      <!-- <a
        v-if="!file.name.match(imgExtensions)"
        :href="fileBaseURL + file.path"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{{ file.name }}</span>
      </a> -->
      <div  class="image">
        <va-button v-if="file.type !== 0 && file.id" 
        title="Сделать аватаром"
        size="small"
        @click="() => assignAvatar(file.id)"
        icon="ss-idea"
        />
        <va-image
          fit="contain"
          @click="() => showImage(i)"
          @file-removed="(file:File) =>  deleteFile(file)"
          :src="fileBaseURL + file.path"
          :fallbackText="file.name"
         
        />						
        <va-button v-if="file.id"
        @click="() => deleteFileById(file.id)"
        size="small"
        icon="ss-delete"
        color="danger" />

        </div>
 

    </div>
  </div>
</template>

<style lang="scss" scoped>
.gallery {
  .card {
    margin: 0.3rem;
    height: 100px;
    width: 200px;
    cursor: pointer;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      position: relative;
    }
    a::before {
      content: "";
      background-image: white;
      background-size: cover;
      position: absolute;
      top: -20px;
      right: 0px;
      bottom: -5px;
      left: 0px;
      opacity: 0.2;
    }

    .image {
      height: 100%;
    }
    .tekst_sverhu_kartinki_sprava_1 {

      position: absolute;
      
      bottom: 10%;
      
      text-transform: uppercase;
      
      color: #c9c9c9;
      
      width: 30px;
      
      right: 0px;
      
      text-align: right;
      
      opacity: 0; transition: 2s;
      
      }
      
      .tekst_sverhu_kartinki_sprava_1 span {
      
      font: bold 24px/44px Helvetica, Sans-Serif;
      
      background: #00000094;
      
      padding: 8px;
      
      }
  }
}
</style>
