import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Home from "@/views/Home.vue";
import PersonalAccount from "@/views/PersonalAccount.vue";
import Info from "@/components/profile/Info.vue";
import Notifications from "@/components/profile/Notifications.vue";
import NotFound from "@/views/NotFound.vue";
import Room from "@/views/Room.vue";
import CreateRoom from "@/views/CreateRoom.vue";
import RoomRating from "@/views/RoomRating.vue";
import FirstPage from "@/views/FirstPage.vue";


export const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      layout: "main",
    },
  },
  {
    path: "/",
    name: "FirstPage",
    component: FirstPage,
    meta: {
      requiresAuth: false,
      layout: "main",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: PersonalAccount,
    meta: {
      requiresAuth: true,
      layout: "main",
    },
    children: [
      {
        name: "Info",
        path: "",
        component: Info,
      },
      {
        name: "Notifications",
        path: "notifications",
        component: Notifications,
      },
    ],
  },
  {
    path: "/room/:roomId",
    name: "Room",
    component: Room,
    meta: {
      requiresAuth: true,
      layout: "main",
    },
  },
  {
    path: "/room/:roomId/rating",
    name: "RoomRating",
    component: RoomRating,
    meta: {
      requiresAuth: true,
      layout: "main",
    },
  },
  {
    path: "/room/new",
    name: "RoomNew",
    component: CreateRoom,
    meta: {
      requiresAuth: true,
      layout: "main",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];
