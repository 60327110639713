import apiAxios from "./apiAxios";
import useAuthStore from "@/store/authStore";
import authService from "../authService";
import { closeConnection } from "@/signalr/connections";

let refreshTokenPromise;

const setup = (store) => {
    apiAxios.interceptors.request.use((config) => {
		const token = store.tokens?.accessToken;
		if (token) {
			config.headers["Authorization"] = 'Bearer ' + token;
		}
		return config;
	}, (error) => {
		return Promise.reject(error);
	});
    apiAxios.interceptors.response.use(
		res => res,
		async (err) => {
			const originalConfig = err.config;
			if (originalConfig.url !== "/api/auth/login" && err.response) {
				// Access Token was expired
				if (err.response.status === 401) {
					if (!originalConfig._retry) {
						originalConfig._retry = true;
						try {
							if (refreshTokenPromise) {
								await refreshTokenPromise;
							}
							else {
								refreshTokenPromise = refreshToken();
								await refreshTokenPromise;
								refreshTokenPromise = null;
							}
							return apiAxios(originalConfig);
						} catch (_error) {
							logout();
							return Promise.reject(_error);
						}
					}
					else {
						logout()
					}
				}
			}
			return Promise.reject(err);
		},
		
    );
	const refreshToken = async () => {
		const authStore = useAuthStore();
		const tokens = await authService.refreshToken(authStore.tokens)
		authStore.refreshToken(tokens);
	}
	const logout = () => {
		const authStore = useAuthStore();
		closeConnection();
		authStore.setUser(null);
		authStore.refreshToken(null);
		router.push({ name: "Login" });
	}
};
export default setup;