<script setup>
import { useStore } from 'vuex';
import * as genders from '@/constants/gender'
import Avatar from '@/components/user/Avatar.vue'
import { useAuthStore } from '@/store';

const authStore = useAuthStore();

const store = useStore()

</script>


<template>
    <Avatar :avatarPath="authStore.user.avatarPath" />
    <p>Username: {{ authStore.user.userName }}</p>
    <p>Gender: {{ authStore.user.gender === genders.Male ? 'Male' : 'Female' }}</p>
    <p>Age: {{ authStore.user.age }}</p>
    <p v-if="authStore.user.city">City: {{ authStore.user.city?.name }}</p>
    <p>Phone number: {{ authStore.user?.phoneNumber }}</p>
    <p>email: {{ authStore.user?.email }}</p>
</template>