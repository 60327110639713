import apiAxios from "./axios/apiAxios";
//import userService from "./userService";
const authService = {
  register: async (payload) => {
    const response = await apiAxios.post("/api/auth/register", payload);
    return response.data;
  },
  editProfile: async (payload) => {
    const response = await apiAxios.put("/api/auth/editProfile", payload);
    return response.data;
  },
  login: async ( user ) => {
    const response = await apiAxios.post("/api/auth/login", {
      email: user.email,
      password: user.password
    });
    return response.data;
  },
  refreshToken: async (tokens) => {
    const response = await apiAxios.post("/api/auth/refresh-token", tokens);
    return response.data;
  },
};
export default authService;
