import apiAxios from "./axios/apiAxios"

const userService = {
    getUser: async (id) => {
        const response = await apiAxios.get(`/api/user/${id}`)
        const user = response.data
        return user
    },
    async assignAvatar(fileId: number, userId: number) {
        let response = await apiAxios.put(`api/file/assignAvatar/${fileId}/${userId}`, 

        )
        const avatar = response.data;
        return avatar;
	},

    async  getBothLiked(userId: number, start?: Date, end?: Date) {
        let response = await apiAxios.get(`api/user/get-both-liked/${userId}`,{
            params: {
                start,
                end,
            },
        });
        const likedUser = response.data;
        return likedUser;
        },
        async  getLikedMe(userId: number, start: Date, end: Date) {
            let response = await apiAxios.get(`api/user/get-liked-me/${userId}`,{
                params: {
                    start,
                    end,
                },
            });
            const likedUser = response.data;
            return likedUser;
            },
            async  getLikedI(userId: number, start: Date, end: Date) {
                let response = await apiAxios.get(`api/user/ge-liked-i/${userId}`,{
                    params: {
                        start,
                        end,
                    },
                });
                const likedUser = response.data;
                return likedUser;
                },
                async  getWhoICommunicate(userId: number, start: Date, end: Date) {
                    let response = await apiAxios.get(`api/user/get-who-i-communicate/${userId}`,{
                        params: {
                            start,
                            end,
                        },
                    });
                    const likedUser = response.data;
                    return likedUser;
                },
                async bun(userId: number) {
                    let response = await apiAxios.put(`api/user/bun/${userId}`)
                },
                async unBun(userId: number) {
                    let response = await apiAxios.put(`api/user/un-bun/${userId}`)
                },
}

export default userService