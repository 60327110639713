<script setup lang="ts">
import { useForm, useToast } from "vuestic-ui";
import {  ref,onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import useAuthStore from "@/store/authStore";
import Register from "./Register.vue";
import userService from "@/services/userService";
import Account from "@/models/Account";
import AvatarNameCityListItem from "@/components/user/AvatarNameCityListItem.vue";
import { de } from "element-plus/es/locale";
const authStore = useAuthStore();
const fileBaseURL =  `${ import.meta.env.VITE_BACKEND_URL}/`;
const router = useRouter();
const route = useRoute();

const { init } = useToast();
const isModalOpen = ref(false);
const handleSubmit = () => {
  router.push({ name: "Register" });
 // isModalOpen.value = true;
};
const bothLiked =  ref([] as Account[]);
onMounted(async () => {
  bothLiked.value  = await userService.getBothLiked(authStore.user.id);
});

</script>

<template>
  <div class="home-page">
    <div class="title">
      <h1>Личный кабинет</h1>
    </div>
    <div>
      <va-button
      @click="handleSubmit()"
      >Перейти в профиль</va-button>
  </div>
    <div class="d-flex justify-center mt-3 center">
<va-card>
  <va-card-title>
    <h2>Информация</h2>
  </va-card-title>
  <va-card-content>
<va-list>
  <va-list-label>Мы друг другу понравились </va-list-label>
  <va-list-item v-for="user in bothLiked"
  :key = "user.id" class="list__item">
  <AvatarNameCityListItem
  :user = "user"
  :needCity=true
  />
  </va-list-item>
</va-list>
    
  </va-card-content>
</va-card>
    </div>
  </div>
  <va-modal
  v-model="isModalOpen"
  fullscreen>
  <!-- <template #content="{ cancel }">
    <register
      :editMode=true
      @submit="handleSubmit"
      @cancel="cancel"></register>
  </template> -->
</va-modal>
</template>

<style scoped lang="less">
@import url("@/assets/styles.css");
@import url("@/assets/base.less");
.center {
  margin: 0 auto;
}
.title {
  padding-top: 5%;
  text-align: center;
}
button {
  margin-right: 4%;
}
</style>
