<script setup lang="ts">
import { computed } from "vue";
import moment from "moment";
import { useAuthStore } from "@/store/authStore";
import Message from "@/models/Message";

const emit = defineEmits<{
  click: [value: Message];
  //	delete: [id: number];
}>();

const props = defineProps<{
  message: Message;
}>();
</script>

<template>
  <va-list-item class="list-item">
    <va-card class="comment">
      <va-card-title>
        <va-list-item-section avatar>
          <va-avatar size="small" color="meetprimary"> </va-avatar>
        </va-list-item-section>
        <va-list-item-section class="username">
          <va-list-item-label>
            {{ props.message.user.userName }}
          </va-list-item-label>
        </va-list-item-section>
        <va-list-item-section icon>
          <va-list-item-label>
            {{ moment(props.message.date).format("DD.MM.YYYY HH:mm") }}
          </va-list-item-label>
        </va-list-item-section>
      </va-card-title>
      <va-card-content>
        <div
          @click="emit('click', message)"
          class="comment_text flex px-0"
          :lines="15"
        >
          {{ message.text }}
        </div>
      </va-card-content>
    </va-card>
  </va-list-item>
</template>

<style lang="scss" scoped>
.list-item {
  margin: 5px;

  .comment {
    width: 100%;

    .username {
      font-size: 16px;
    }
    &_text {
      font-weight: normal;
      text-align: left;
      cursor: pointer;
    }
  }
}
</style>
