export enum Gender {
  female = 0,
  male = 1,
}

export const genders = [
  {
    id: Gender.female,
    text: "Женщина",
  },
  {
    id: Gender.male,
    text: "Мужчина",
  },
];
export const gender = (genderId: Gender) => {
  return genders.find((x) => x.id == genderId)?.text;
};

export default gender;
